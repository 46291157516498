import React, { Component } from "react";
import { formatPrice } from "../common/check";
import { payAppointment } from "../../services/appointmentService";
import { Trans } from "react-i18next";

class PaymentMethod extends Component {
    onDeposit = async (resultCreateAppointment, paymentMethod) => {
        const { amountDeposit, customer, passby } = this.props;

        const paymentUrl = await payAppointment(
            resultCreateAppointment.Value,
            amountDeposit,
            window.location.href +
            "betaalstatus?idAppointment=" +
            resultCreateAppointment.Value + "&method=deposit",
            paymentMethod,
            customer,
            passby
        );

        if (paymentUrl)
            window.location = paymentUrl;
    };

    onPayment = async (resultCreateAppointment, paymentMethod) => {
        const { priceTotal, customer, passby } = this.props;

        const paymentUrl = await payAppointment(
            resultCreateAppointment.Value,
            priceTotal,
            window.location.href +
            "betaalstatus?idAppointment=" +
            resultCreateAppointment.Value,
            paymentMethod,
            customer,
            passby
        );

        if (paymentUrl)
            window.location = paymentUrl;
    };

    render() {
        const {
            paymentMethod,
            selectedPaymentMethod,
            selectPaymentMethod,
            resultCreateAppointment,
            amountDeposit,
            priceTotal,
            onGiftcardPayment
        } = this.props;

        return (
            <div
                className={
                    selectedPaymentMethod == paymentMethod
                        ? "paymentOption selected"
                        : "paymentOption"
                }
            >
                <div className="paymentSelector">
                    <button onClick={() => selectPaymentMethod(paymentMethod)}>
                        <span />
                    </button>
                </div>
                <div
                    className="paymentLogo"
                    onClick={() => selectPaymentMethod(paymentMethod)}
                >
                    {(paymentMethod === "credit card" || paymentMethod === "card") ? (
                        <React.Fragment>
                            {" "}
                            <svg className="shape-logo-visa">
                                <use xlinkHref="#shape-logo-visa" />
                            </svg>
                            <svg className="shape-logo-mastercard">
                                <use xlinkHref="#shape-logo-mastercard" />
                            </svg>
                        </React.Fragment>
                    ) : (
                        <svg className={"shape-logo-" + paymentMethod}>
                            <use xlinkHref={"#shape-logo-" + paymentMethod} />
                        </svg>
                    )}
                </div>
                <div className="paymentDesc">
                    <h3>
                        {(paymentMethod === "credit card" || paymentMethod === "card")
                            ? "Creditcard"
                            : paymentMethod === "bancontact"
                                ? "Bancontact"
                                : "iDeal"}
                    </h3>
                    <p>
                        {(paymentMethod === "credit card" || paymentMethod === "card") ? (
                            <Trans i18nKey="appointment-confirmed.pay.creditcard">
                                Veilig betalen via jouw creditcard.
                            </Trans>
                        ) : (
                            <Trans i18nKey="appointment-confirmed.pay.bank">
                                Veilig betalen via jouw eigen bank.
                            </Trans>
                        )}
                    </p>
                </div>
                <div className="paymentButtons">
                    {amountDeposit && (
                        <button
                            onClick={() =>
                                this.onDeposit(resultCreateAppointment, paymentMethod)
                            }
                        >
                            <Trans i18nKey="appointment-confirmed.pay.button.deposit">
                                <span>
                                    Aanbetalen €&nbsp;{{ price: formatPrice(amountDeposit) }}
                                </span>
                            </Trans>
                        </button>
                    )}
                    {!onGiftcardPayment &&
                        amountDeposit && (
                            <button
                                onClick={() =>
                                    this.onPayment(resultCreateAppointment, paymentMethod)
                                }
                            >
                                <Trans i18nKey="appointment-confirmed.pay.button.all">
                                    <span>
                                        Hele bedrag €&nbsp;{{ price: formatPrice(priceTotal) }}
                                    </span>
                                </Trans>
                            </button>
                        )}
                    {!onGiftcardPayment &&
                        !amountDeposit && (
                            <button
                                onClick={() =>
                                    this.onPayment(resultCreateAppointment, paymentMethod)
                                }
                            >
                                <Trans i18nKey="appointment-confirmed.pay.button">
                                    Direct betalen
                                </Trans>
                            </button>
                        )}
                    {onGiftcardPayment && (
                        <button onClick={() => onGiftcardPayment(paymentMethod)}>
                            <Trans i18nKey="giftcard.pay">Afrekenen</Trans>
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default PaymentMethod;
